<template>
  <div class="home">
    <headerTop title="充值" />
    <div class="tab grade3">
      <div class="center btn" :class="obj.payChannelId == item.id ? 'active' : ''" @click="handleType(i, item)" :key="item.id" v-for="(item, i) in payChannelList">
        {{ item.name }}
      </div>

      <!-- <div class="slider" ref="slider" :style="{ left: left + 'px' }"></div> -->
    </div>
    <!-- <div class="tab space_around">
      <div class="center btn" :class="payType == 1 ? 'active' : ''" @click="handleType(1)">
        <svg t="1708494904533" class="icon" viewBox="0 0 1228 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4039">
          <path
            d="M530.8928 703.1296a41.472 41.472 0 0 1-35.7376-19.8144l-2.7136-5.5808L278.272 394.752a18.7392 18.7392 0 0 1-2.048-8.1408 19.968 19.968 0 0 1 20.48-19.3536c4.608 0 8.8576 1.4336 12.288 3.84l234.3936 139.9296a64.4096 64.4096 0 0 0 54.528 5.9392L1116.2624 204.8C1004.9536 80.896 821.76 0 614.4 0 275.0464 0 0 216.576 0 483.6352c0 145.7152 82.7392 276.8896 212.2752 365.5168a38.1952 38.1952 0 0 1 17.2032 31.488 44.4928 44.4928 0 0 1-2.1504 12.3904l-27.6992 97.4848c-1.3312 4.608-3.328 9.3696-3.328 14.1312 0 10.752 9.216 19.3536 20.48 19.3536 4.4032 0 8.0384-1.536 11.776-3.584l134.5536-73.3184c10.1376-5.5296 20.7872-8.96 32.6144-8.96 6.2976 0 12.288 0.9216 18.0736 2.5088 62.72 17.0496 130.4576 26.5728 200.5504 26.5728C953.7024 967.168 1228.8 750.592 1228.8 483.6352c0-80.9472-25.4464-157.1328-70.0416-224.1024l-604.9792 436.992-4.4544 2.4064a42.1376 42.1376 0 0 1-18.432 4.1984z"
            fill="#727477"
            p-id="4040"
          />
        </svg>
        微信
      </div>
      <div class="center btn" :class="payType == 2 ? 'active' : ''" @click="handleType(2)">
        <svg t="1708494611211" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3334">
          <path
            d="M1024.0512 701.0304V196.864A196.9664 196.9664 0 0 0 827.136 0H196.864A196.9664 196.9664 0 0 0 0 196.864v630.272A196.9152 196.9152 0 0 0 196.864 1024h630.272a197.12 197.12 0 0 0 193.8432-162.0992c-52.224-22.6304-278.528-120.32-396.4416-176.64-89.7024 108.6976-183.7056 173.9264-325.3248 173.9264s-236.1856-87.2448-224.8192-194.048c7.4752-70.0416 55.552-184.576 264.2944-164.9664 110.08 10.3424 160.4096 30.8736 250.1632 60.5184 23.1936-42.5984 42.496-89.4464 57.1392-139.264H248.064v-39.424h196.9152V311.1424H204.8V267.776h240.128V165.632s2.1504-15.9744 19.8144-15.9744h98.4576V267.776h256v43.4176h-256V381.952h208.8448a805.9904 805.9904 0 0 1-84.8384 212.6848c60.672 22.016 336.7936 106.3936 336.7936 106.3936zM283.5456 791.6032c-149.6576 0-173.312-94.464-165.376-133.9392 7.8336-39.3216 51.2-90.624 134.4-90.624 95.5904 0 181.248 24.4736 284.0576 74.5472-72.192 94.0032-160.9216 150.016-253.0816 150.016z"
            fill="#727477"
            p-id="3335"
          />
        </svg>
        支付宝
      </div>
      <div class="center btn" :class="payType == 3 ? 'active' : ''" @click="handleType(3)">
        <svg t="1708494940773" class="icon" viewBox="0 0 1228 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4192">
          <path
            d="M83.33333333 305.675v507.69c0 76.365 69.3 138.465 154.575 138.465h746.01c85.185 0 154.53-62.1 154.53-138.465V305.675H83.33333333z m1055.115-80.145v-23.85c0-76.365-69.345-138.51-154.53-138.51H237.90833333C152.63333333 63.17 83.33333333 125.27 83.33333333 201.68v23.85h1055.115z m-264.87 337.185A157.635 157.635 0 0 1 1073.33333333 714.5a157.5 157.5 0 1 1-296.37-74.385l-120.6-135.675-1.035-91.215 85.41 1.98 1.305 67.995 61.92 3.24-2.07 66.825 63.225 0.45 8.46 9zM915.83333333 782a67.5 67.5 0 1 0 0-135 67.5 67.5 0 0 0 0 135z"
            fill="#727477"
            p-id="4193"
          />
        </svg>
        卡密
      </div>
      <div class="slider" ref="slider" :style="{ left: left + 'px' }"></div>
    </div> -->
    <div class="kamin_tip" v-show="obj.payChannelId == 3">联系平台客服（2199193281）,卡密充值享9.8折优惠,大额充值联系客服享更多优惠。</div>
    <div v-if="obj.payChannelId == 3" class="kami">
      <input type="text" class="kami_input" v-model="kami" placeholder="请输入卡密" />
    </div>
    <div class="grade3 header" v-else>
      <div v-for="(item, index) in rechargeList" :key="index" class="card center" @click="handlec(item)" :class="item.isselect ? 'actives' : ''">
        {{ item.price }}
      </div>
    </div>
    <div class="main_center_bottom">
      <div :class="['confirm', { confirmed: select }]" @click="handleAgree"></div>
      <div class="content">我已满18岁，并阅读和同意《用户协议》、《隐私政策》和《反洗钱协议》，承诺理性消费。</div>
    </div>
    <div v-if="obj.payChannelId == 3" :class="['bottom center', { activess: kami && select }]" @click="handlePay">支付</div>
    <div v-else :class="['bottom center', { activess: ready && select }]" @click="handlePay">
      支付 <template v-if="money">{{ money }} 元</template>
    </div>
    <div class="auth_status" :style="{ color: isRealCheck == '0' ? '#727477' : $main }" @click="handleAuth">
      {{ isRealCheck == '0' ? '您还未实名认证，点击立即进行实名认证' : '已实名认证' }}
    </div>

    <!-- <button @click="ceshi">测试demo</button> -->

    <alert class="auth_alert" :visible="isAuth" title="实名认证" @cancel="handleCancel" @confirm="handleConfirm">
      <input type="text" v-model="authForm.realName" placeholder="请输入您的真实姓名" />
      <input type="text" v-model="authForm.idNum" placeholder="请输入您的身份证号码" />
    </alert>
    <alert class="qr_alert" :visible="wxorder" title="扫码支付" :hideCancel="true" confirmText="取消" @confirm="handleCancel">
      <div id="code" class="flex-center">
        <canvas id="canvas"></canvas>
      </div>
      <div class="tip">订单生成后,请用微信或支付宝扫描二维码进行支付</div>
    </alert>
    <!-- <alert class="qr_alert" :visible="yunshanfu" title="云闪付" :hideCancel="true" confirmText="取消" @confirm="handleCancel">
      <div  class="flex-center">
        <iframe :src="yunshanfuLink" frameborder="0" style="width: 100%;height: 100%;margin: 0;"></iframe>
      </div>
      <div class="tip">订单生成后,请云闪付app扫描二维码进行支付</div>
    </alert> -->
  </div>
</template>

<script>
import QRCode from 'qrcode';
import { getRechargelist, realNameAuthentication, kamiPay, getUserInfo, createOrder, payChannelList, getRechargeGreadeNorm, gameConditionById } from '@/api/index';
export default {
  data() {
    return {
      authForm: {
        idNum: '',
        realName: '',
        password: '',
        password1: '',
      },
      ceshiLink: 'https://www.so.com/s?ie=utf-8&src=hao_360so_b_per&shb=1&hsid=86eaa31be63df89d&ssid=&q=iframe%E8%A2%AB%E5%B1%8F%E8%94%BD&cp=14c000a000',
      loading: false,
      wxorder: false,
      yunshanfu: false,
      // yunshanfuLink:'',
      isAuth: false,
      isSubmit: true,
      isRealCheck: false,
      money: 0,
      select: false,
      left: 1,
      ready: false,
      payType: 1,
      rechargeList: [],
      payChannelList: [],
      kami: '',
      obj: {
        payChannelId: 8,
        goodsPrice: null,
        goodsId: null,
        goodsNum: 1,
      },
      currentGrade: 0,
      nextGameCondition: {},
      levelPage: {
        page: 1,
        size: 20,
      },
      levelList: [],
    };
  },

  mounted() {
    if (this.$store.state.LOGIN_IS_SHOW) return;

    getUserInfo().then(res => {
      if (res?.data?.code == 200) {
        this.isRealCheck = res?.data?.data?.isRealCheck;
        this.$store.commit('USER_INFO', res?.data?.data);
      }
    });
    payChannelList({}).then(res => {
      this.payChannelList = res.data.data.rows.sort((a, b) => {
        return a.sort - b.sort;
      });
      this.obj.payChannelId = this.payChannelList[0].id;
      this.obj.payParty = this.payChannelList[0].payParty;
      this.obj.payType = this.payChannelList[0].payType;
      this.queryRechangeList();
    });
  },
  methods: {
    // ceshi(){
    //   payDemo({partyType:14,payType:3,money:100}).then((res)=>{
    //     console.log(res);
    //   })
    // },
    findLevel(i) {
      console.log(213);

      if (this.levelList.length == i) {
        this.nextGameCondition.rulerRecharge = 'max';
        // this.$store.commit('setLevel', this.currentGrade, this.nextGameCondition);
        this.$store.commit('setLevel', { currentGrade: this.currentGrade, nextGameCondition: this.nextGameCondition });
        return false;
      }
      gameConditionById(this.levelList[i].gameConditionId).then(res => {
        if (this.$store.state.USER_INFO.gradeConsumeValue >= res?.data?.data.rulerConsumeBlend && this.$store.state.USER_INFO.gradeRechargeValue >= res?.data?.data.rulerRecharge) {
          this.currentGrade = i;
          // console.log('符合', i);

          this.findLevel(i + 1);
        } else {
          // console.log('不符合', i);

          this.nextGameCondition = res?.data?.data;
          this.$store.commit('setLevel', { currentGrade: this.currentGrade, nextGameCondition: this.nextGameCondition });
        }
      });
    },
    getLevelList() {
      getRechargeGreadeNorm(this.levelPage).then(res => {
        this.levelList.push(...res.data.data.list);
        this.levelList = this.levelList.sort((a, b) => {
          return a.grade - b.grade;
        });
        console.log('充值等级', this.levelList);
        if (res.data.data.list.length == this.levelPage.size) {
          this.levelPage.page++;
          this.getLevelList();
        } else {
          this.findLevel(0);
        }
      });
    },
    // getrechangeList() {
    //   getUserInfo().then((res) => {
    //     this.isRealCheck = res.data.data.isRealCheck;
    //     this.$store.commit("USER_INFO", res.data.data);
    //   });
    //   getRechargelist().then((res) => {
    //     this.list = res.data.data.map((item) => {
    //       item.isselect = false;
    //       return item;
    //     });
    //     this.list.forEach((e) => {
    //       if (e.price >= this.payChannelList[0].minPrice) {
    //         this.payList.push(e);
    //       }
    //     });
    //   });
    // },
    playAudio() {
      this.$store.commit('playAudio');
    },
    handlePay() {
      this.playAudio();
      if (!this.kami && !this.ready) return;
      if (this.isRealCheck == '0') {
        this.$message({
          offset: 50,
          message: '请先进行实名认证',
          type: 'warning',
        });
        return;
      }
      if (this.$store.state.LOGIN_IS_SHOW) {
        this.$router.push('/login');
        return;
      }
      this.loading = true;
      if (!this.select) {
        this.$message({
          offset: 50,
          message: '请先同意《用户协议》，《隐私政策》等内容',
          type: 'warning',
        });
        return;
      }
      console.log(this.obj);
      

      if (this.obj.payParty == 18) {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });
        createOrder(this.obj).then(res => {
          // uni.showLoading({
          //   title: '加载中',
          // });
          // console.log('usdt支付',res);

          if (res?.data?.code == 200) {
            // this.getLevelList();
            this.wxorder = true;
            let timer = setTimeout(() => {
              var canvas = document.getElementById('canvas'); //获取到canvas
              var code = document.getElementById('code'); //获取到code容器
              console.log(canvas, code);
              QRCode.toCanvas(canvas, res?.data?.data.payUrl, error => {
                if (error) console.error(error);
                console.log('success!');
              });
              var image = new Image(); //实例一个img
              image.src = canvas.toDataURL('image/png'); //转换成base64格式路径的png图片
              image.style.width = '100%'; //设置样式
              code.appendChild(image); //添加到code 容器中
              canvas.style.display = 'none'; //隐藏掉canvas
              clearTimeout(timer);
              loading.close();
            }, 200);
          } else {
            loading.close();
            this.$message({
              offset: 50,
              message: '订单创建异常',
              type: 'warning',
            });
          }
        });

        return;
      }
      if ((this.obj.payParty == 17 && this.obj.payType == 6)||(this.obj.payParty == 17 && this.obj.payType == 8)) {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });
        createOrder(this.obj).then(res => {
          if (res?.data?.code == 200) {
            // this.getLevelList();
            this.wxorder = true;
            let timer = setTimeout(() => {
              var canvas = document.getElementById('canvas'); //获取到canvas
              var code = document.getElementById('code'); //获取到code容器
              console.log(canvas, code);
              QRCode.toCanvas(canvas, res?.data?.data.payUrl, error => {
                if (error) console.error(error);
                console.log('success!');
              });
              var image = new Image(); //实例一个img
              image.src = canvas.toDataURL('image/png'); //转换成base64格式路径的png图片
              image.style.width = '100%'; //设置样式
              code.appendChild(image); //添加到code 容器中
              canvas.style.display = 'none'; //隐藏掉canvas
              clearTimeout(timer);
              loading.close();
            }, 200);
          } else {
            loading.close();
            this.$message({
              offset: 50,
              message: '订单创建异常',
              type: 'warning',
            });
          }
        });
        return;
      }
      if (this.obj.payChannelId == 3) {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });
        kamiPay({ password: this.kami }).then(res => {
          loading.close();
          if (res.data.code == 200) {
            this.kami = '';
            getUserInfo().then(res => {
              if (res.data.code == 200) {
                this.$store.commit('USER_INFO', res.data.data);
                this.getLevelList();
              }
            });
            this.$message({
              offset: 50,
              message: res.data.msg,
              type: 'success',
            });
          } else {
            setTimeout(() => {
              this.$message({
                offset: 50,
                message: '订单创建异常',
                type: 'warning',
              });
            }, 200);
          }
        });
      } else if (this.obj.payChannelId == 5 || this.obj.payChannelId == 7 || this.obj.payChannelId == 8) {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });
        createOrder(this.obj).then(res => {
          if (res?.data?.code == 200) {
            // this.getLevelList();
            this.wxorder = true;
            let timer = setTimeout(() => {
              var canvas = document.getElementById('canvas'); //获取到canvas
              var code = document.getElementById('code'); //获取到code容器
              console.log(canvas, code);
              QRCode.toCanvas(canvas, res?.data?.data.payUrl, error => {
                if (error) console.error(error);
                console.log('success!');
              });
              var image = new Image(); //实例一个img
              image.src = canvas.toDataURL('image/png'); //转换成base64格式路径的png图片
              image.style.width = '100%'; //设置样式
              code.appendChild(image); //添加到code 容器中
              canvas.style.display = 'none'; //隐藏掉canvas
              clearTimeout(timer);
              loading.close();
            }, 200);
          } else {
            loading.close();
            this.$message({
              offset: 50,
              message: '订单创建异常',
              type: 'warning',
            });
          }
        });
      }
      // else if(this.obj.payChannelId==10){
      //   const loading = this.$loading({
      //     lock: true,
      //     text: 'Loading',
      //     spinner: 'el-icon-loading',
      //     background: 'rgba(0, 0, 0, 0.7)',
      //   });
      //   createOrder(this.obj).then(res => {
      //     loading.close();
      //     if (res.data.code == 200) {
      //       // window.open(res.data.data.payUrl);
      //       this.yunshanfuLink=res.data.data.payUrl
      //       this.yunshanfu=true

      //     } else {
      //       setTimeout(() => {
      //         this.$message({
      //           offset: 50,
      //           message: '订单创建异常',
      //           type: 'warning',
      //         });
      //       }, 200);
      //     }
      //   });
      // }
      else {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });
        createOrder(this.obj).then(res => {
          console.log(res);
          loading.close();
          if (res.data.code == 200) {
            window.open(res.data.data.payUrl);
          } else {
            setTimeout(() => {
              this.$message({
                offset: 50,
                message: '订单创建异常',
                type: 'warning',
              });
            }, 200);
          }
        });
      }
    },
    queryRechangeList() {
      getRechargelist().then(res => {
        this.rechargeList = res?.data?.data || [];
      });
    },
    handleConfirm() {
      this.playAudio();
      if (!this.authForm.realName) {
        this.$message({
          offset: 50,
          message: '请输入您的真实姓名',
          type: 'warning',
        });
        return;
      }
      if (!this.authForm.idNum) {
        this.$message({
          offset: 50,
          message: '请输入您的身份证号码',
          type: 'warning',
        });
        return;
      }
      this.isSubmit = false;
      realNameAuthentication(this.authForm).then(res => {
        if (res?.data?.code === 200) {
          this.transactionLink = '';
          let timer = setTimeout(() => {
            this.isSubmit = true;
            this.$message({
              offset: 50,
              message: '实名认证成功',
              type: 'success',
            });
            clearTimeout(timer);
          }, 500);
        } else {
          this.isSubmit = true;
        }
      });
    },
    handleAuth() {
      if (this.isRealCheck == '0') {
        this.playAudio();
        if (this.$store.state.LOGIN_IS_SHOW) {
          this.$router.push('/login');
          return;
        }
        this.isAuth = true;
      }
    },
    handleCancel() {
      this.playAudio();
      this.authForm = {
        idNum: '',
        realName: '',
        password: '',
        password1: '',
      };
      this.isAuth = false;
      this.wxorder = false;
      this.yunshanfu = false;
    },
    handlec(res) {
      this.playAudio();
      this.obj.goodsId = res.id;
      this.obj.goodsPrice = res.price;
      this.money = res.price;
      this.rechargeList.forEach(e => {
        e.isselect = false;
      });
      res.isselect = true;
      this.ready = true;
    },
    handleAgree() {
      this.playAudio();
      this.select = !this.select;
    },
    handleType(res, item) {
      console.log(111, res, item);

      this.playAudio();
      // this.payType = res;
      // this.index = res;
      this.kami = '';
      this.obj.payChannelId = item.id;
      this.obj.payParty = item.payParty;
      this.obj.payType = item.payType;
      console.log(this.obj);

      this.obj.goodsId = null;
      this.obj.goodsPrice = null;
      this.rechargeList.forEach(e => {
        e.isselect = false;
      });
      this.ready = false;
      // const element = this.$refs.slider;
      // this.left = (res - 1) * element.offsetWidth;
    },
  },
};
</script>

<style lang="less" scoped>
.pay {
  margin: 0 auto;
  @media @max750 {
    width: 140px;
  }
}
.home {
  margin: 0 @pcSpace;
  @media @max750 {
    margin: 0 @mSpace;
  }
}
.tab {
  width: 50%;
  min-width: 260px;
  // height: 42px;
  background: #323436;
  border-radius: 15px;
  margin: 0 auto;
  transition: all 0.3s linear;
  color: #727477;
  font-size: 14px;
  position: relative;
  // display: flex;
  // flex-wrap: wrap;
  @media @max750 {
    // width: 240px;
    // height: 30px;
  }
}
.kamin_tip {
  .sc(18px,#fff);
  margin: 20px 0;
  text-align: center;
  @media @max750 {
    font-size: 14px;
  }
}
.btn {
  width: 100%;
  height: 60px;
  z-index: 2;
  position: relative;
  font-size: 18px;
  @media @max750 {
    // width: 80px;
    height: 30px;
    font-size: 12px;
  }
  .icon {
    width: 28px;
    height: 28px;
    margin: 5px;
    @media @max750 {
      width: 20px;
      height: 20px;
    }
  }
}
.slider {
  position: absolute;
  width: 120px;
  height: 100%;
  // background: linear-gradient(-90deg, #3a97e4 0%, #25f484 100%);
  box-sizing: border-box;
  border: 1px solid @main;
  border-radius: 70px;
  transition: left 0.2s ease-in-out;
  @media @max750 {
    width: 80px;
  }
}
.active {
  color: @main;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  border: 1px solid @main;
  border-radius: 15px;
  svg {
    path {
      fill: @main;
      transition: all 0.2s ease-in-out;
    }
  }
}
.header {
  // margin-top: 29px;
  margin: 29px auto 0;
  width: 50%;
  @media @max750 {
    width: 100%;
  }
}
.card {
  width: 147px;
  height: 98px;
  background: #323436;
  border-radius: 14px 14px 14px 14px;
  position: relative;
  margin: 0 auto;
  color: #727477;
  font-weight: 700;
  font-size: 35px;
  // padding-top: 20px;
  @media @max750 {
    // padding-top: 20px;

    font-size: 25px;
    width: 100%;
    height: 70px;
  }
}
.main_center_bottom {
  display: flex;
  margin-top: 21px;
  .confirm {
    .wh(16px, 16px);
    cursor: pointer;
    margin-top: 3px;
    border-radius: 50%;
    border: 1px solid @bd2;
    @media @max750 {
      .wh(12px, 12px);
      margin-top: 2px;
      border-color: #727477;
    }
  }
  .confirmed {
    position: relative;
    border-color: @main;
    &::after {
      content: '';
      .abs-ctl;
      .wh(6px, 6px);
      border-radius: 50%;
      background: @main;
    }
    @media @max750 {
      border-color: @main;
      &::after {
        .wh(4px, 4px);
        background: @main;
      }
    }
  }
  .content {
    flex: 1;
    color: #fff;
    font-size: 16px;
    padding-left: 10px;
    @media @max750 {
      font-size: 12px;
      padding-left: 6px;
      white-space: pre-line;
    }
  }
}

.bottom {
  width: 100%;
  height: 56px;
  background: linear-gradient(360deg, rgba(114, 116, 119, 0.2) 0%, rgba(114, 116, 119, 0.1) 100%);
  opacity: 1;
  border: 1px solid #727477;
  margin: 0 auto;
  margin-top: 14px;

  font-size: 25px;
  @media @max750 {
    font-size: 18px;
    height: 40px;
  }
}
.actives {
  color: #fff;
  background: linear-gradient(-90deg, rgba(58, 151, 228, 0.3) 0%, rgba(37, 244, 132, 0.3) 100%);
  opacity: 1;
  border: 1px solid;
  border-left-color: rgba(37, 244, 132, 1);
  border-right-color: rgba(58, 151, 228, 1);
  border-top-color: rgba(37, 244, 132, 1);
  border-bottom-color: rgba(58, 151, 228, 1);
}
.activess {
  .btn-bg;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.auth_status {
  font-size: 18px;
  margin-top: 40px;
  text-align: center;
  text-decoration: underline;
  @media @max750 {
    font-size: 14px;
  }
}

.auth_alert {
  ::v-deep .alert_content {
    input {
      z-index: 2;
      .wh(100%, 40px);
      .sc(16px, #fff);
      background: #323436;
      border-radius: 35px;
      text-align: center;
      margin-top: 20px;
      border: none;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
.qr_alert {
  ::v-deep .alert_content {
    #canvas {
      .wh(200px, 200px);
    }
    .tip {
      margin-top: 20px;
      text-align: center;
    }
  }
}
.kami {
  width: 100%;
  height: 56px;
  margin-top: 14px;
  border: 1px solid #585a60;
  border-radius: 4px;
  overflow: hidden;
  @media @max750 {
    height: 40px;
  }
}
.kami_input {
  width: 100%;
  font-size: 21px;
  height: 100%;
  border: none;
  color: #fff;
  text-align: center;
  outline: none;
  background: none;
  @media @max750 {
    height: 40px;
    font-size: 15px;
  }
}
.kami_input::-webkit-input-placeholder {
  color: #fff;
}
</style>
